import _util from "./util";
import _bindings from "bindings";
import _NQuads from "./NQuads";
import _IdentifierIssuer from "./IdentifierIssuer";
var exports = {};
const util = _util;

const URDNA2015Native = _bindings("urdna2015");

const api = {};
exports = api; // expose helpers

api.NQuads = _NQuads;
api.IdentifierIssuer = _IdentifierIssuer;
/**
 * Asynchronously canonizes an RDF dataset.
 *
 * @param dataset the dataset to canonize.
 * @param options the options to use:
 *          algorithm the canonicalization algorithm to use: `URDNA2015`.
 * @param [callback(err, canonical)] called once the operation completes.
 *
 * @return a Promise that resolves to the canonicalized RDF Dataset.
 */

api.canonize = util.callbackify(async function (dataset, options) {
  let callback;
  const promise = new Promise((resolve, reject) => {
    callback = (err, canonical) => {
      if (err) {
        return reject(err);
      }
      /*if(options.format === 'application/n-quads') {
        canonical = canonical.join('');
      }
      canonical = _parseNQuads(canonical.join(''));*/


      resolve(canonical);
    };
  }); // back-compat with legacy dataset

  if (!Array.isArray(dataset)) {
    dataset = api.NQuads.legacyDatasetToQuads(dataset);
  } // TODO: convert algorithms to Promise-based async


  if (options.algorithm === "URDNA2015") {
    URDNA2015Native.main({
      dataset
    }, callback);
  } else if (!("algorithm" in options)) {
    throw new Error("No RDF Dataset Canonicalization algorithm specified.");
  } else {
    throw new Error("Invalid RDF Dataset Canonicalization algorithm: " + options.algorithm);
  }

  return promise;
});
/**
 * Synchronously canonizes an RDF dataset.
 *
 * @param dataset the dataset to canonize.
 * @param options the options to use:
 *          algorithm the canonicalization algorithm to use: `URDNA2015`.
 *
 * @return the RDF dataset in canonical form.
 */

api.canonizeSync = function (dataset, options) {
  // back-compat with legacy dataset
  if (!Array.isArray(dataset)) {
    dataset = api.NQuads.legacyDatasetToQuads(dataset);
  }

  if (options.algorithm === "URDNA2015") {
    return URDNA2015Native.mainSync({
      dataset
    });
  }

  if (!("algorithm" in options)) {
    throw new Error("No RDF Dataset Canonicalization algorithm specified.");
  }

  throw new Error("Invalid RDF Dataset Canonicalization algorithm: " + options.algorithm);
};

export default exports;